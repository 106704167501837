// File has been generated by scripts/fetch-translations.ts. Do not modify it manually.
import { components } from './api/v1';

export const globalAppTranslations: {
  en: Record<string, any>;
  pl: Record<string, any>;
} = {
  "en": {
    "BOOKING": {
      "COACH_PRICING_DESCRIPTION": "* Coach pricing can be found on the dedicated subpage",
      "FILTERS_TITLE": "Filter Results",
      "NO_RECORDS_FOUND_TEXT": "No Results",
      "RECORDS_COUNT": "Showing {{ RECORDS_LENGTH }} of {{ RECORDS_TOTAL }} results",
      "REFRESH_BUTTON_TOOLTIP": "Refresh",
      "RESERVATION_MODAL_ERRORS_ALREADY_RESERVED_BY_CURRENT_USER": "You already have a reservation for this training.",
      "RESERVATION_MODAL_ERRORS_IN_VERIFICATION_1": "The provided email address currently has a reservation in 'under verification' status.",
      "RESERVATION_MODAL_ERRORS_IN_VERIFICATION_2": "If you want to complete the reservation, click the link sent to your email address.",
      "RESERVATION_MODAL_ERRORS_IN_VERIFICATION_3": "To cancel a reservation under verification, use the account management page.",
      "RESERVATION_MODAL_ERRORS_TOO_MANY_ATTENDERS": "The reservation exceeded the participant limit.",
      "RESERVATION_MODAL_ERRORS_UNKNOWN": "An unknown error occurred.",
      "RESERVATION_MODAL_ERRORS_USER_ALREADY_EXIST": "A user with the provided email address already exists.",
      "RESERVATION_MODAL_ERRORS_USER_NOT_FOUND": "No user found with the given email address.",
      "RESERVATION_MODAL_NEXT_BUTTON_LABEL": "Next",
      "RESERVATION_MODAL_RESERVATION_CANCEL_BUTTON_TEXT": "Cancel",
      "RESERVATION_MODAL_RESERVATION_EXISTING_CLIENT": "Returning Client",
      "RESERVATION_MODAL_RESERVATION_NEW_CLIENT": "New Client",
      "RESERVATION_MODAL_RESERVATION_SUBMIT_BUTTON_TEXT": "Reserve",
      "RESERVATION_MODAL_RESERVATION_SUCCESS_MESSAGE": "Successfully created reservation with ID: {{ reservation_id }}.",
      "RESERVATION_MODAL_RESERVATION_SUMMARY_DATE_TEXT": "Date",
      "RESERVATION_MODAL_RESERVATION_SUMMARY_MAX_ATTENDERS_TEXT": "Maximum Number of Participants",
      "RESERVATION_MODAL_RESERVATION_SUMMARY_SERVICE_TEXT": "Service",
      "RESERVATION_MODAL_RESERVATION_SUMMARY_STATUS": "Status",
      "RESERVATION_MODAL_RESERVATION_SUMMARY_TIME_COACH_TEXT": "Coach",
      "RESERVATION_MODAL_RESERVATION_SUMMARY_TIME_DURATION_TEXT": "Duration",
      "RESERVATION_MODAL_RESERVATION_SUMMARY_TIME_TEXT": "Time",
      "RESERVATION_MODAL_RESERVATION_SUMMARY_TITLE": "Summary",
      "RESERVATION_MODAL_RESERVATION_YOUR_DATA_ADDITIONAL_INFO": "Additional Information",
      "RESERVATION_MODAL_RESERVATION_YOUR_DATA_EMAIL": "Email Address*",
      "RESERVATION_MODAL_RESERVATION_YOUR_DATA_EXISTING_CLIENT_FIELD_TOOLTIP": "Data will be retrieved from the account associated with the email.",
      "RESERVATION_MODAL_RESERVATION_YOUR_DATA_FULLNAME": "First and Last Name*",
      "RESERVATION_MODAL_RESERVATION_YOUR_DATA_PHONE_NUMBER": "Phone Number",
      "RESERVATION_MODAL_RESERVATION_YOUR_DATA_PRIVACY_POLICY_CHECKBOX_LABEL": "By making a reservation, you agree to our privacy policy",
      "RESERVATION_MODAL_RESERVATION_YOUR_DATA_TITLE": "Your Information",
      "RESERVATION_MODAL_TITLE": "Reservation",
      "RESERVATION_MODAL_VERIFICATION_MUST_ACCEPT": "Accept the privacy policy to continue",
      "RESERVATION_MODAL_VERIFICATION_MUST_PASS": "Complete verification to continue",
      "RESET_FILTERS": "Clear Filters",
      "RESET_TIME_COMPONENT_RESET": "Reset",
      "SEARCH": "Search",
      "SUMMARY": {
        "PROBLEM_CONTACT_US": "Contact us.",
        "PROBLEM_TEXT": "Having trouble confirming the reservation?",
        "STAGE_1_DESCRIPTION": "The selected slot will not be visible to other users for the next hour.",
        "STAGE_1_TITLE": "The reservation has been made but is not confirmed",
        "STAGE_2_DESCRIPTION": "Click the link received in the email message.",
        "STAGE_2_TITLE": "Confirm Email Address",
        "STAGE_3_DESCRIPTION_LINE_1": "The coach has been notified of your booking.",
        "STAGE_3_DESCRIPTION_LINE_2": "A link to manage your reservation has been sent to your email.",
        "STAGE_3_TITLE": "Reservation Accepted",
        "TITLE_1": "Reservation",
        "TITLE_2": "Summary"
      },
      "TABLE_DURATION_LABEL_FROM": "Minimum Service Duration (minutes)",
      "TABLE_DURATION_LABEL_TO": "Maximum Service Duration (minutes)",
      "TABLE_FILTER_ATTENDERS_COUNT_TOOLTIP_GROUP": "Group classes up to {{ MAX_ATTENDERS }} people",
      "TABLE_FILTER_ATTENDERS_COUNT_TOOLTIP_SINGLE": "One-on-one sessions with a coach",
      "TABLE_FILTER_ATTENDERS_LABEL_FROM": "Minimum Number of Participants",
      "TABLE_FILTER_ATTENDERS_LABEL_TO": "Maximum Number of Participants",
      "TABLE_FILTER_COACH_NAME_AND_SURNAME_LABEL": "First and Last Name",
      "TABLE_FILTER_COACH_NAME_AND_SURNAME_PLACEHOLDER": "Select Coach",
      "TABLE_FILTER_COACH_SELECT_SERVICE_LABEL": "Service Name",
      "TABLE_FILTER_COACH_SELECT_SERVICE_PLACEHOLDER": "Select Service",
      "TABLE_FILTER_DATE_DATE_RANGE": "Date Range",
      "TABLE_FILTER_DATE_REFRESH": "Refresh",
      "TABLE_FILTER_DATE_TO_LABEL_FROM": "Not earlier than (date)",
      "TABLE_FILTER_DATE_TO_LABEL_TO": "Not later than (date)",
      "TABLE_FILTER_TIME_TIME_FROM_LABEL": "From",
      "TABLE_FILTER_TIME_TIME_RANGE_SELECTION_TEXT": "Select Range",
      "TABLE_FILTER_TIME_TIME_RANGE_TEXT": "Time Range",
      "TABLE_FILTER_TIME_TIME_TO_LABEL": "To",
      "TABLE_FILTER_TIME_TO_LABEL_FROM": "Not earlier than (time)",
      "TABLE_FILTER_TIME_TO_LABEL_TO": "Not later than (time)",
      "TABLE_ROW_TITLE_ACTIONS": "Actions",
      "TABLE_ROW_TITLE_ACTIONS_RESERVATION_BUTTON_TEXT": "Reserve",
      "TABLE_ROW_TITLE_ATTENDERS_COUNT": "Number of Participants",
      "TABLE_ROW_TITLE_COACH": "Coach",
      "TABLE_ROW_TITLE_DATE": "Date",
      "TABLE_ROW_TITLE_DURATION": "Duration",
      "TABLE_ROW_TITLE_SERVICE": "Service",
      "TABLE_ROW_TITLE_TIME": "Time",
      "TITLE": "Reservations",
      "VALIDATIONS_NAME_CANNOT_BE_EMPTY": "First and last name cannot be empty!",
      "VALIDATIONS_PHONE_NUMBER_INVALID": "The phone number is invalid."
    },
    "DATA_MANAGEMENT": {
      "HOME": {
        "ACCOUNT_DATA_NAME_AND_SURNAME_EMAIL_ADRESS_LABEL": "Email Address*",
        "ACCOUNT_DATA_NAME_AND_SURNAME_EMAIL_CHANGE_NOT_SUPPORTED": "Changing the email address is not supported.",
        "ACCOUNT_DATA_NAME_AND_SURNAME_LABEL": "First and Last Name",
        "ACCOUNT_DATA_PHONE_NUMBER_LABEL": "Phone Number*",
        "ACCOUNT_DATA_UPDATE_BUTTON_LABEL": "Update",
        "ACCOUNT_DATA_UPDATE_CANNOT_CHANGE_EMAIL": "Email address cannot be changed",
        "CANCEL_RESERVATION": "Cancel Reservation",
        "CANCEL_RESERVATION_ACCEPT_LABEL": "Yes, I want to cancel",
        "CANCEL_RESERVATION_CANCEL_LABEL": "No",
        "CANCEL_RESERVATION_ERROR": "Failed to cancel reservation. Please try again or contact us.",
        "CANCEL_RESERVATION_MODAL_LINE_1": "Canceling the reservation will notify the coach of this action.",
        "CANCEL_RESERVATION_MODAL_LINE_2": "This operation cannot be undone.",
        "CANCEL_RESERVATION_MODAL_LINE_3": "After cancellation, you can make a new reservation for the same training if there are still available spots.",
        "CANCEL_RESERVATION_MODAL_TITLE": "Are you sure you want to cancel the reservation?",
        "CANCEL_RESERVATION_SUCCESS": "Reservation canceled successfully!",
        "DATE_LABEL": "Date Range",
        "DATE_PLACEHOLDER": "Select Date Range",
        "DELETE_ACCOUNT_ERROR": "An error occurred while deleting the account. Please try again or contact us.",
        "DELETE_ACCOUNT_SUCCESS": "Account deleted successfully!",
        "DELETE_MY_ACCOUNT_MODAL_BUTTON_CANCEL": "No, do not delete",
        "DELETE_MY_ACCOUNT_MODAL_BUTTON_CONFIRM": "Yes, I want to delete my account",
        "DELETE_MY_ACCOUNT_MODAL_LINE_1": "This action will remove all your data from our system.",
        "DELETE_MY_ACCOUNT_MODAL_LINE_2": "This action is irreversible.",
        "DELETE_MY_ACCOUNT_MODAL_TITLE": "Delete My Account",
        "EXPORT_DATA_ERROR": "An error occurred during data export. If the error persists, please contact us.",
        "HISTORY_MOBILE_FILTERS_MODAL_TITLE": "Reservation History / Filtering",
        "HISTORY_MODAL_TITLE": "Reservations",
        "LOGOUT_ERROR": "Failed to log out. Please try again.",
        "LOGOUT_SUCCESS": "Successfully logged out!",
        "RESERVATION_HISTORY_TITLE": "Reservation History",
        "RESERVATION_SEARCH_FILTERS_BUTTON_TEXT": "Filter",
        "RESERVATION_SEARCH_TITLE": "Find Reservation",
        "SCREEN_LOADING_TEXT": "Verifying identity...",
        "SEARCH_LABEL": "Search",
        "SEARCH_PLACEHOLDER": "Search",
        "UPDATE_DATE_MODAL_BUTTON_CANCEL": "No",
        "UPDATE_DATE_MODAL_BUTTON_CONFIRM": "Yes, update",
        "UPDATE_DATE_MODAL_LINE_1": "Updating data will send an email to each coach with whom you have a booked training.",
        "UPDATE_DATE_MODAL_TITLE": "Are you sure you want to update the data?",
        "USER_ACTIONS_DELETE_ACCOUNT_BUTTON_TOOLTIP": "Delete Account",
        "USER_ACTIONS_DOWNLOAD_DATA_BUTTON_TOOLTIP": "Download Your Data",
        "USER_ACTIONS_LOGOUT_BUTTON_TOOLTIP": "Log Out",
        "USER_UPDATE_ERROR": "Failed to update data. Please try again or contact us.",
        "USER_UPDATE_SUCCESS": "Data updated successfully!",
        "VIEW_SWITCH_ACCOUNT_DATA": "Account Data",
        "VIEW_SWITCH_RESERVATION": "Reservation"
      },
      "LOGIN": {
        "EMAIL_NOT_FOUND_MESSAGE": "Email address not found in our system.",
        "EMAIL_PLACEHOLDER": "Enter your email address",
        "EMAIL_SENT_FAILED_MESSAGE": "An error occurred while sending the email.",
        "EMAIL_SENT_MESSAGE": "Email has been sent.",
        "EMAIL_TIMEOUT_MESSAGE": "Please wait {{ TIME_MINUTES }} minutes and {{ TIME_SECONDS }} seconds before sending another email.",
        "MAGIC_LINK_EXPLANATION": "You will receive an email with a one-time link. Clicking the link will automatically log you into the system.",
        "MAGIC_LINK_EXPLANATION_TOOLTIP": "What is a Magic Link?",
        "PASS_VERIFICATION_TO_CONT_TOOLTIP": "Complete verification to continue",
        "SEND_MAGIC_LINK_BUTTON_LABEL": "Send Magic Link",
        "SUBTITLE": "Dashboard Login",
        "TITLE": "Welcome Back"
      }
    },
    "GALLERY": {
      "INSTA_INVITATION": "For the latest photos, visit our",
      "TITLE": "Gallery"
    },
    "HOME": {
      "CONTACT": {
        "ADDRESS_LABEL": "Address",
        "CONTACT_PHONE_NUMBERS_LABEL": "Contact Phone Numbers",
        "EMAIL_LABEL": "Email",
        "OPENING_HOURS": "24h, 7 days a week",
        "OPENING_HOURS_LABEL": "Opening Hours",
        "OPENING_HOURS_STAR": "Only for club members",
        "PHONE_NUMBER_LANG_EN_LABEL": "EN",
        "PHONE_NUMBER_LANG_EN_TOOLTIP": "Number supports calls in English",
        "PHONE_NUMBER_LANG_PL_LABEL": "PL",
        "PHONE_NUMBER_LANG_PL_TOOLTIP": "Number supports calls in Polish",
        "PHONE_NUMBER_PLACEHOLDER": "Phone Number",
        "TITLE": "Interested?"
      },
      "HERO": {
        "ACTION_BUTTON": "Start Today",
        "ACTIVE_COMMUNITY": "Active Community",
        "CATCH_PHRASE": "With us, it's possible",
        "COACHES_WITH_PASSION": "Passionate Coaches",
        "DESCRIPTION_1": "Whether you started training yesterday or 15 years ago, Determination awaits you to help you achieve your goals.",
        "DESCRIPTION_2": "As one of the few in Warsaw, we provide world-class equipment—exactly the kind you'll find at competitions, for which our coaches will prepare you.",
        "LEFT_IMAGE_ALT": "Athletic Man",
        "PROFFESIONAL_GEAR": "Professional Equipment",
        "TITLE": "Push Your Limits"
      },
      "PRICING": {
        "LEFT_CARD": {
          "DURATION": "one-time",
          "PRICE": "50 zł",
          "PROPERTY_1_POSITIVE": "Single access",
          "PROPERTY_2": "No workout plan",
          "PROPERTY_3": "No consulations",
          "TITLE_LINE_1": "Membership",
          "TITLE_LINE_2": "Single Entry"
        },
        "LOCK_FEE": "* A one-time fee of 50 PLN for a locker key must be added to the monthly passes",
        "MIDDLE_CARD": {
          "DURATION": "monthly *",
          "PRICE": "250 zł",
          "PROPERTY_1_POSITIVE": "24/7 Access",
          "PROPERTY_2": "No workout plan",
          "PROPERTY_3": "No consulations",
          "TITLE_LINE_1": "Membership",
          "TITLE_LINE_2": "Monthly"
        },
        "PREMIUM_NOTICE": "* The premium membership has a limited number of spots",
        "PURCHASE_OPTIONS": "Memberships available for purchase on-site",
        "RIGHT_CARD": {
          "DURATION": "monthly *",
          "PRICE": "390 zł",
          "PROPERTY_1_POSITIVE": "24/7 Access",
          "PROPERTY_2_POSITIVE": "Workout plan",
          "PROPERTY_3_POSITIVE": "Consulations",
          "TITLE_LINE_1": "Membership",
          "TITLE_LINE_2": "Premium"
        },
        "TITLE": "Memberships"
      },
      "TEAM": {
        "COACH_LANG_LABEL_EN": "EN",
        "COACH_LANG_LABEL_PL": "PL",
        "COACH_LANG_TOOLTIP_EN": "Coach speaks English",
        "COACH_LANG_TOOLTIP_PL": "Coach speaks Polish",
        "MAKE_APPOINTMENT_BUTTON": "Make an Appointment",
        "MORE_ABOUT_ME": "More about me...",
        "TITLE": "Meet Our Team"
      }
    },
    "OTHER": {
      "COACH_SELECT": {
        "LABEL": "Coach",
        "PLACEHOLDER": "First and Last Name"
      },
      "FLEXIBLE_TOOLTIP": {
        "MODAL_TITLE": "Information"
      },
      "GO_BACK": "Go back",
      "MAGIC_LINK_ERROR": "The provided code is invalid or the link has expired.",
      "NOT_FOUND": "Not Found",
      "RESERVATION_STATUS": {
        "CANCELLED": "Cancelled",
        "CONFIRMED": "Confirmed",
        "VERIFICATION": "Under Verification"
      },
      "RESET": "Reset",
      "SOMETHING_WENT_WRONG_ERR_MSG": "Something went wrong...",
      "SOMETHING_WENT_WRONG_ERR_WITH_CODE_MSG": "Something went wrong... Error code: {{ error_code }}",
      "STATUS_SELECT": {
        "LABEL": "Status",
        "PLACEHOLDER": "Select Status"
      }
    },
    "ROOT": {
      "DETERMINACJA_GYM_LOGO_ALT": "Determination Gym Logo",
      "FOOTER": {
        "BOOKING_LINK_TEXT": "Reservations",
        "CONTACT_LINK_TEXT": "Contact",
        "LANG_ENGLISH_TEXT": "English",
        "LANG_POLISH_TEXT": "Polish",
        "LANG_SELECT_LABEL": "Language",
        "LANG_SELECT_PLACEHOLDER": "Select Language",
        "MY_ACCOUNT_LINK_TEXT": "My Account",
        "PRIVACY_POLITY_LINK_TEXT": "Privacy Policy"
      },
      "MY_ACCOUNT": "My Account",
      "NAVIGATION": {
        "CONTACT": "Contact",
        "GALLERY": "Gallery",
        "HOME": "Home",
        "LEADER_BOARD": "Leaderboard",
        "OUR_TEAM": "Our Team",
        "PRICING": "Pricing",
        "RESERVATIONS": "Reservations"
      }
    },
    "SERVICES_TRANSL": {
      "YOGA": "Yoga"
    }
  },
  "pl": {
    "BOOKING": {
      "COACH_PRICING_DESCRIPTION": "* Cennik trenera zajduję się na jego dedytkowanej podstronie",
      "FILTERS_TITLE": "Filtrowanie wyników",
      "NO_RECORDS_FOUND_TEXT": "Brak wyników",
      "RECORDS_COUNT": "Pokazano {{ RECORDS_LENGTH }} z {{ RECORDS_TOTAL }} wyników",
      "REFRESH_BUTTON_TOOLTIP": "Odśwież",
      "RESERVATION_MODAL_ERRORS_ALREADY_RESERVED_BY_CURRENT_USER": "Posiadasz już rezerwację na ten trening.",
      "RESERVATION_MODAL_ERRORS_IN_VERIFICATION_1": "Podany adres e-mail posiada aktualnie rezerwację w stanie \"do weryfikacji\".",
      "RESERVATION_MODAL_ERRORS_IN_VERIFICATION_2": "Jeżeli chcesz zakończyć rezerwację, kliknij w link przesłany na adres e-mail.",
      "RESERVATION_MODAL_ERRORS_IN_VERIFICATION_3": "Aby anulować rezerwację w weryfikacji skorzystaj ze strony zarządzania kontem.",
      "RESERVATION_MODAL_ERRORS_TOO_MANY_ATTENDERS": "Rezerwacja przekroczyła limit ilości uczestników.",
      "RESERVATION_MODAL_ERRORS_UNKNOWN": "Rezerwacja przekroczyła limit ilości uczestników.",
      "RESERVATION_MODAL_ERRORS_USER_ALREADY_EXIST": "Użytkownik o podanym adresie e-mail już istnieje.",
      "RESERVATION_MODAL_ERRORS_USER_NOT_FOUND": "Nie znaleziono użytkownika z danym adresem e-mail.",
      "RESERVATION_MODAL_NEXT_BUTTON_LABEL": "Dalej",
      "RESERVATION_MODAL_RESERVATION_CANCEL_BUTTON_TEXT": "Anuluj",
      "RESERVATION_MODAL_RESERVATION_EXISTING_CLIENT": "Powracający klient",
      "RESERVATION_MODAL_RESERVATION_NEW_CLIENT": "Nowy klient",
      "RESERVATION_MODAL_RESERVATION_SUBMIT_BUTTON_TEXT": "Rezerwuję",
      "RESERVATION_MODAL_RESERVATION_SUCCESS_MESSAGE": "Pomyślnie stworzono rezerwację z ID: {{ reservation_id }}.",
      "RESERVATION_MODAL_RESERVATION_SUMMARY_DATE_TEXT": "Data",
      "RESERVATION_MODAL_RESERVATION_SUMMARY_MAX_ATTENDERS_TEXT": "Maksymalna ilość uczestników",
      "RESERVATION_MODAL_RESERVATION_SUMMARY_SERVICE_TEXT": "Serwis",
      "RESERVATION_MODAL_RESERVATION_SUMMARY_STATUS": "Status",
      "RESERVATION_MODAL_RESERVATION_SUMMARY_TIME_COACH_TEXT": "Trener",
      "RESERVATION_MODAL_RESERVATION_SUMMARY_TIME_DURATION_TEXT": "Czas trwania",
      "RESERVATION_MODAL_RESERVATION_SUMMARY_TIME_TEXT": "Godzina",
      "RESERVATION_MODAL_RESERVATION_SUMMARY_TITLE": "Podusmowanie",
      "RESERVATION_MODAL_RESERVATION_YOUR_DATA_ADDITIONAL_INFO": "Dodatkowe informacje",
      "RESERVATION_MODAL_RESERVATION_YOUR_DATA_EMAIL": "Adres e-mail*",
      "RESERVATION_MODAL_RESERVATION_YOUR_DATA_EXISTING_CLIENT_FIELD_TOOLTIP": "Dane zostaną pobrane z konta powiązanego z mailem.",
      "RESERVATION_MODAL_RESERVATION_YOUR_DATA_FULLNAME": "Imię i nazwisko*",
      "RESERVATION_MODAL_RESERVATION_YOUR_DATA_PHONE_NUMBER": "Numer telefonu",
      "RESERVATION_MODAL_RESERVATION_YOUR_DATA_PRIVACY_POLICY_CHECKBOX_LABEL": "Składając rezerwację zgdzasz się z naszą polityką prytwatności",
      "RESERVATION_MODAL_RESERVATION_YOUR_DATA_TITLE": "Twoje dane",
      "RESERVATION_MODAL_TITLE": "Rezerwacja",
      "RESERVATION_MODAL_VERIFICATION_MUST_ACCEPT": "Zaakceptuj politykę prywatności aby kontyuować",
      "RESERVATION_MODAL_VERIFICATION_MUST_PASS": "Przejdź weryfikację aby kontyuować",
      "RESET_FILTERS": "Wyczyść filtry",
      "RESET_TIME_COMPONENT_RESET": "Resetuj",
      "SEARCH": "Szukaj",
      "SUMMARY": {
        "PROBLEM_CONTACT_US": "Skontaktuj się z nami.",
        "PROBLEM_TEXT": "Problem z potwierdzieniem rezerwacji?",
        "STAGE_1_DESCRIPTION": "Wybrany termin nie będzie widoczny dla innych użytkowników przez kolejną godzinę.",
        "STAGE_1_TITLE": "Rezerwacja została złożona, ale jest nie potwierdzona",
        "STAGE_2_DESCRIPTION": "Kliknij w link otrzymany w wiadomości e-mail.",
        "STAGE_2_TITLE": "Potwierdź adres e-mail",
        "STAGE_3_DESCRIPTION_LINE_1": "Trener otrzymał informację o Twoim zamównieniu.",
        "STAGE_3_DESCRIPTION_LINE_2": "Na Twój adres e-mail przyszedł link do strony, gdzie możesz zarządzać rezerwacją.",
        "STAGE_3_TITLE": "Rezerwacja została przyjęta",
        "TITLE_1": "Podsumowanie",
        "TITLE_2": "Rezerwacji"
      },
      "TABLE_DURATION_LABEL_FROM": "Minimalny czas trwania usługi (minuty)",
      "TABLE_DURATION_LABEL_TO": "Maksymalny czas trwania usługi (minuty)",
      "TABLE_FILTER_ATTENDERS_COUNT_TOOLTIP_GROUP": "Zajęcia grupowe do {{ MAX_ATTENDERS }} osób",
      "TABLE_FILTER_ATTENDERS_COUNT_TOOLTIP_SINGLE": "Zajęcia 1 na 1 z trenerem",
      "TABLE_FILTER_ATTENDERS_LABEL_FROM": "Minimalna liczba uczestników",
      "TABLE_FILTER_ATTENDERS_LABEL_TO": "Maksymalna liczba uczestników",
      "TABLE_FILTER_COACH_NAME_AND_SURNAME_LABEL": "Imię i nazwisko",
      "TABLE_FILTER_COACH_NAME_AND_SURNAME_PLACEHOLDER": "Wybierz trenrea",
      "TABLE_FILTER_COACH_SELECT_SERVICE_LABEL": "Nazwa serwisu",
      "TABLE_FILTER_COACH_SELECT_SERVICE_PLACEHOLDER": "Wybierz serwis",
      "TABLE_FILTER_DATE_DATE_RANGE": "Zakres dat",
      "TABLE_FILTER_DATE_REFRESH": "Odśwież",
      "TABLE_FILTER_DATE_TO_LABEL_FROM": "Nie wcześniej niż (data)",
      "TABLE_FILTER_DATE_TO_LABEL_TO": "Nie wcześniej niż (data)",
      "TABLE_FILTER_TIME_TIME_FROM_LABEL": "Od",
      "TABLE_FILTER_TIME_TIME_RANGE_SELECTION_TEXT": "Wybierz zakres",
      "TABLE_FILTER_TIME_TIME_RANGE_TEXT": "Zakres godzin",
      "TABLE_FILTER_TIME_TIME_TO_LABEL": "Do",
      "TABLE_FILTER_TIME_TO_LABEL_FROM": "Nie wcześniej niż (godzina)",
      "TABLE_FILTER_TIME_TO_LABEL_TO": "Nie później niż (godzina)",
      "TABLE_ROW_TITLE_ACTIONS": "Akcje",
      "TABLE_ROW_TITLE_ACTIONS_RESERVATION_BUTTON_TEXT": "Rezerwuję",
      "TABLE_ROW_TITLE_ATTENDERS_COUNT": "Liczba uczestników",
      "TABLE_ROW_TITLE_COACH": "Trener",
      "TABLE_ROW_TITLE_DATE": "Data",
      "TABLE_ROW_TITLE_DURATION": "Czas trwania",
      "TABLE_ROW_TITLE_SERVICE": "Serwis",
      "TABLE_ROW_TITLE_TIME": "Godzina",
      "TITLE": "Rezerawcje",
      "VALIDATIONS_NAME_CANNOT_BE_EMPTY": "Imię i nazwisko nie może być puste!",
      "VALIDATIONS_PHONE_NUMBER_INVALID": "Numer telefonu jest nieprawidłowy."
    },
    "DATA_MANAGEMENT": {
      "HOME": {
        "ACCOUNT_DATA_NAME_AND_SURNAME_EMAIL_ADRESS_LABEL": "Adres e-mail*",
        "ACCOUNT_DATA_NAME_AND_SURNAME_EMAIL_CHANGE_NOT_SUPPORTED": "Zmiana adresu e-mail nie jest obsługiwana.",
        "ACCOUNT_DATA_NAME_AND_SURNAME_LABEL": "Imię i nazwisko",
        "ACCOUNT_DATA_PHONE_NUMBER_LABEL": "Numer telefonu*",
        "ACCOUNT_DATA_UPDATE_BUTTON_LABEL": "Aktualizuj",
        "ACCOUNT_DATA_UPDATE_CANNOT_CHANGE_EMAIL": "Adresu e-mail nie można zmienić",
        "CANCEL_RESERVATION": "Anuluj rezerwację",
        "CANCEL_RESERVATION_ACCEPT_LABEL": "Tak, chcę anulować",
        "CANCEL_RESERVATION_CANCEL_LABEL": "Nie, zamknij dialog",
        "CANCEL_RESERVATION_ERROR": "Nie udało się anulować rezeracji. Spróbuj ponownie, bądź skontakuj się z nami.",
        "CANCEL_RESERVATION_MODAL_LINE_1": "Anulowanie rezerwacji spowoduje powiadomienie trenera o wykonanej akcji.",
        "CANCEL_RESERVATION_MODAL_LINE_2": "Operacji nie można cofnąć.",
        "CANCEL_RESERVATION_MODAL_LINE_3": "Po anulowaniu będziesz miał możliwość złozenia nowej rezerwacji na ten sam trening, jeżeli będą jeszcze wolne miejsca.",
        "CANCEL_RESERVATION_MODAL_TITLE": "Czy na pewno chcesz anulować rezerwację?",
        "CANCEL_RESERVATION_SUCCESS": "Pomyślnie anulowano rezerwację!",
        "DATE_LABEL": "Zakres dat",
        "DATE_PLACEHOLDER": "Wybierz zakres dat",
        "DELETE_ACCOUNT_ERROR": "Wystąpił błąd podczas usuwania rezerwacji. Spróbuj ponownie, bądź skontakuj się z nami.",
        "DELETE_ACCOUNT_SUCCESS": "Pomyślnie usunięto konto!",
        "DELETE_MY_ACCOUNT_MODAL_BUTTON_CANCEL": "Nie, nie usuwaj",
        "DELETE_MY_ACCOUNT_MODAL_BUTTON_CONFIRM": "Tak, chce usunąc swoje konto",
        "DELETE_MY_ACCOUNT_MODAL_LINE_1": "Akcja spowoduje usunięcie z naszego systemu wszystkich Twoich danych.",
        "DELETE_MY_ACCOUNT_MODAL_LINE_2": "Akcja jest nieodrwacalna.",
        "DELETE_MY_ACCOUNT_MODAL_TITLE": "Usuń moje konto",
        "EXPORT_DATA_ERROR": "Wystąpił błąd podczas exportu danych. Jeżeli błąd będzie się powtarzał skontaktuj się z nami.",
        "HISTORY_MOBILE_FILTERS_MODAL_TITLE": "Historia rezeracji / Filtracja",
        "HISTORY_MODAL_TITLE": "Rezerwacje",
        "LOGOUT_ERROR": "Nie udało się wylogować. Spróbuj ponownie.",
        "LOGOUT_SUCCESS": "Pomyślnie wylogowano!",
        "NO_RECORDS": "Nie znaleziono wyników",
        "RESERVATION_HISTORY_TITLE": "Historia rezerwacji",
        "RESERVATION_SEARCH_FILTERS_BUTTON_TEXT": "Filtruj",
        "RESERVATION_SEARCH_TITLE": "Znajdź rezerwację",
        "SCREEN_LOADING_TEXT": "Weryfikacja tożsamości...",
        "SEARCH_LABEL": "Szukaj",
        "SEARCH_PLACEHOLDER": "Szukaj",
        "TOTAL_RECORDS": "Pokazano {{ CURRENT }} z {{ TOTAL }} wyników",
        "UPDATE_DATE_MODAL_BUTTON_CANCEL": "Nie",
        "UPDATE_DATE_MODAL_BUTTON_CONFIRM": "Tak, aktualizuję",
        "UPDATE_DATE_MODAL_LINE_1": "Aktualizacja danych spowoduje wysłanie maila do każdego trenera, u którego masz zerezerwowany trening.",
        "UPDATE_DATE_MODAL_TITLE": "Czy na pewno chcesz zaaktualizować dane?",
        "USER_ACTIONS_DELETE_ACCOUNT_BUTTON_TOOLTIP": "Usuń konto",
        "USER_ACTIONS_DOWNLOAD_DATA_BUTTON_TOOLTIP": "Pobierz swoje dane",
        "USER_ACTIONS_LOGOUT_BUTTON_TOOLTIP": "Wyloguj się",
        "USER_UPDATE_ERROR": "Nie udało się zaaktualizować danych. Spróbuj ponownie, bądź skontakuj się z nami.",
        "USER_UPDATE_SUCCESS": "Pomyślnie zaaktualizowano dane!",
        "VIEW_SWITCH_ACCOUNT_DATA": "Dane konta",
        "VIEW_SWITCH_RESERVATION": "Rezerawcja"
      },
      "LOGIN": {
        "EMAIL_NOT_FOUND_MESSAGE": "Nie znaleziono adresu e-mail w naszym systemie.",
        "EMAIL_PLACEHOLDER": "Podaj swój adres e-mail",
        "EMAIL_SENT_FAILED_MESSAGE": "Wystąpił błąd podczas wysyłania maila.",
        "EMAIL_SENT_MESSAGE": "Email został wysłany.",
        "EMAIL_TIMEOUT_MESSAGE": "Prosimy poczkać {{ TIME_MINUTES }} minuty i {{ TIME_SECONDS }} sekundy przed wysłaniem kolejnego maila.",
        "MAGIC_LINK_EXPLANATION": "Dostaniesz maila z jednorazowym linkiem. Po kliknięciu w link zostaniesz automatycznie zalogowany do systemu.",
        "MAGIC_LINK_EXPLANATION_TOOLTIP": "Co to magiczny link?",
        "PASS_VERIFICATION_TO_CONT_TOOLTIP": "Przejdź weryfikację aby kontyuować",
        "SEND_MAGIC_LINK_BUTTON_LABEL": "Wyślij magiczny link",
        "SUBTITLE": "Logowanie do dashboardu",
        "TITLE": "Witaj ponowanie"
      }
    },
    "GALLERY": {
      "INSTA_INVITATION": "Po najnowzsze zdjęcia zapraszamy na nasz",
      "TITLE": "Galeria"
    },
    "HOME": {
      "CONTACT": {
        "ADDRESS_LABEL": "Adres",
        "CONTACT_PHONE_NUMBERS_LABEL": "Telefony kontaktowe",
        "EMAIL_LABEL": "Email",
        "OPENING_HOURS": "24h, 7 dni w tygodniu",
        "OPENING_HOURS_LABEL": "Godziny pracy",
        "OPENING_HOURS_STAR": "Tylko dla osób posiadających karnet",
        "PHONE_NUMBER_LANG_EN_LABEL": "EN",
        "PHONE_NUMBER_LANG_EN_TOOLTIP": "Numer obsługuje połączenie w języku angielskim",
        "PHONE_NUMBER_LANG_PL_LABEL": "PL",
        "PHONE_NUMBER_LANG_PL_TOOLTIP": "Numer obsługuje połączenie w języku polskim",
        "PHONE_NUMBER_PLACEHOLDER": "Numer telefonu",
        "TITLE": "Zainteresowany?"
      },
      "HERO": {
        "ACTION_BUTTON": "Zacznij już dziś",
        "ACTIVE_COMMUNITY": "Aktywne community",
        "CATCH_PHRASE": "Z nami, to możliwe",
        "COACHES_WITH_PASSION": "Trenerzy z pasją",
        "DESCRIPTION_1": "Niezależnie od tego, czy zacząlęś trenować wczoraj, czy 15 lat temu, Determinacja czeka na ciebie, aby pomóc ci osiągnąć swoje cele.",
        "DESCRIPTION_2": "Jako nieliczni w Warszawie zapewniamy światowej klasy sprzęt - dokładnie taki, jaki znajdziesz na zawodach, do których przygotują cie nasi trenerzy.",
        "LEFT_IMAGE_ALT": "Wysporotwany mężczyzna",
        "PROFFESIONAL_GEAR": "Profesjonalny sprzęt",
        "TITLE": "Przekrocz swoje granice"
      },
      "PRICING": {
        "LEFT_CARD": {
          "DURATION": "jednorazowo",
          "PRICE": "50 zł",
          "PROPERTY_1_POSITIVE": "Pojedyńczy dostęp",
          "PROPERTY_2": "Brak planu treningowy",
          "PROPERTY_3": "Brak konsultacji",
          "TITLE_LINE_1": "Karnet",
          "TITLE_LINE_2": "Jednorazowy"
        },
        "LOCK_FEE": "* Do karnetów miesięcznych trzeba doliczyć jednorazową opłatę w wyskości 50zł za kluzcyk do szafki",
        "MIDDLE_CARD": {
          "DURATION": "miesięcznie *",
          "PRICE": "250 zł",
          "PROPERTY_1_POSITIVE": "Dostęp 24/7",
          "PROPERTY_2": "Brak planu treningowy",
          "PROPERTY_3": "Brak konsultacji",
          "TITLE_LINE_1": "Karnet",
          "TITLE_LINE_2": "Miesięczny"
        },
        "PREMIUM_NOTICE": "* Karnet premium posiada ogarniczoną ilość miejsc",
        "PURCHASE_OPTIONS": "Karnety dostępne do kupienia na miejscu",
        "RIGHT_CARD": {
          "DURATION": "miesięcznie *",
          "PRICE": "390 zł",
          "PROPERTY_1_POSITIVE": "Dostęp 24/7",
          "PROPERTY_2_POSITIVE": "Plan treningowy",
          "PROPERTY_3_POSITIVE": "Konsultacje",
          "TITLE_LINE_1": "Karnet",
          "TITLE_LINE_2": "Premium"
        },
        "TITLE": "Karnety"
      },
      "TEAM": {
        "COACH_LANG_LABEL_EN": "EN",
        "COACH_LANG_LABEL_PL": "PL",
        "COACH_LANG_TOOLTIP_EN": "Trener mówi w języku angielskim",
        "COACH_LANG_TOOLTIP_PL": "Trener mówi w języku polskim",
        "MAKE_APPOINTMENT_BUTTON": "Umów się",
        "MORE_ABOUT_ME": "Więcej o mnie...",
        "TITLE": "Poznaj nasz team"
      }
    },
    "OTHER": {
      "COACH_SELECT": {
        "LABEL": "Trener",
        "PLACEHOLDER": "Imię i nazwisko"
      },
      "FLEXIBLE_TOOLTIP": {
        "MODAL_TITLE": "Informacja"
      },
      "GO_BACK": "Powrót",
      "MAGIC_LINK_ERROR": "Podany kod jest nieprawidłowy, bądź link wygasł.",
      "NOT_FOUND": "Nie znaleziono",
      "RESERVATION_STATUS": {
        "CANCELLED": "Anulowana",
        "CONFIRMED": "Potwierdzona",
        "VERIFICATION": "W weryfikacji"
      },
      "RESET": "Resetuj",
      "SOMETHING_WENT_WRONG_ERR_MSG": "Cos poszło nie tak...",
      "SOMETHING_WENT_WRONG_ERR_WITH_CODE_MSG": "Cos poszło nie tak... Kod błędu: {{ error_code }}",
      "STATUS_SELECT": {
        "LABEL": "Status",
        "PLACEHOLDER": "Wybierz status"
      }
    },
    "ROOT": {
      "DETERMINACJA_GYM_LOGO_ALT": "Logo siłowni Determinacja",
      "FOOTER": {
        "BOOKING_LINK_TEXT": "Rezerwacje",
        "CONTACT_LINK_TEXT": "Kontakt",
        "LANG_ENGLISH_TEXT": "English",
        "LANG_POLISH_TEXT": "Polski",
        "LANG_SELECT_LABEL": "Język",
        "LANG_SELECT_PLACEHOLDER": "Wybierz język",
        "MY_ACCOUNT_LINK_TEXT": "Moje konto",
        "PRIVACY_POLITY_LINK_TEXT": "Polityka prywatności"
      },
      "MY_ACCOUNT": "Moje konto",
      "NAVIGATION": {
        "CONTACT": "Kontakt",
        "GALLERY": "Galeria",
        "HOME": "Home",
        "LEADER_BOARD": "Tabela wyników",
        "OUR_TEAM": "Nasz team",
        "PRICING": "Cennik",
        "RESERVATIONS": "Rezerwacje"
      }
    },
    "SERVICES_TRANSL": {
      "YOGA": "Yoga"
    }
  }
};

export const globalAppSettings: components['schemas']['AllSettings'] = {
  "$schema": "https://backend.determinacjagym.pl/schemas/AllSettings.json",
  "global": {
    "email": "biuro@determinacjagym.pl",
    "address": "Pabla Nerudy 1, 01-926 Warszawa",
    "addressLink": "https://www.google.com/maps/dir//determinacja+silownia/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x471ecbe5b11b2761:0xc02d84dc889ea851?sa=X&ved=1t:3061&ictx=111",
    "workingHours": "HOME.CONTACT.OPENING_HOURS",
    "instagramLink": "https://www.instagram.com/determinacja_gym",
    "youTubeLink": "",
    "phoneNumbers": [
      {
        "phoneNumber": "+48737470076",
        "phoneNumberDisplayName": "+48 737 470 076",
        "languages": [
          {
            "labelTranslationCode": "HOME.CONTACT.PHONE_NUMBER_LANG_PL_LABEL",
            "tooltipTranslationCode": "HOME.CONTACT.PHONE_NUMBER_LANG_PL_TOOLTIP"
          },
          {
            "labelTranslationCode": "HOME.CONTACT.PHONE_NUMBER_LANG_EN_LABEL",
            "tooltipTranslationCode": "HOME.CONTACT.PHONE_NUMBER_LANG_EN_TOOLTIP"
          }
        ],
        "priority": 1,
        "version": 1
      },
      {
        "phoneNumber": "+48508078267",
        "phoneNumberDisplayName": "+48 508 078 267",
        "languages": [
          {
            "labelTranslationCode": "HOME.CONTACT.PHONE_NUMBER_LANG_PL_LABEL",
            "tooltipTranslationCode": "HOME.CONTACT.PHONE_NUMBER_LANG_PL_TOOLTIP"
          }
        ],
        "priority": 2,
        "version": 1
      }
    ],
    "leftPricingCard": {
      "titleRow1": "HOME.PRICING.LEFT_CARD.TITLE_LINE_1",
      "titleRow2": "HOME.PRICING.LEFT_CARD.TITLE_LINE_2",
      "priceRow": "HOME.PRICING.LEFT_CARD.PRICE",
      "PeriodRow": "HOME.PRICING.LEFT_CARD.DURATION",
      "properties": [
        "HOME.PRICING.LEFT_CARD.PROPERTY_1_POSITIVE",
        "HOME.PRICING.LEFT_CARD.PROPERTY_2",
        "HOME.PRICING.LEFT_CARD.PROPERTY_3"
      ],
      "version": 0
    },
    "middlePricingCard": {
      "titleRow1": "HOME.PRICING.MIDDLE_CARD.TITLE_LINE_1",
      "titleRow2": "HOME.PRICING.MIDDLE_CARD.TITLE_LINE_2",
      "priceRow": "HOME.PRICING.MIDDLE_CARD.PRICE",
      "PeriodRow": "HOME.PRICING.MIDDLE_CARD.DURATION",
      "properties": [
        "HOME.PRICING.MIDDLE_CARD.PROPERTY_1_POSITIVE",
        "HOME.PRICING.MIDDLE_CARD.PROPERTY_2",
        "HOME.PRICING.MIDDLE_CARD.PROPERTY_3"
      ],
      "version": 0
    },
    "rightPricingCard": {
      "titleRow1": "HOME.PRICING.MIDDLE_CARD.TITLE_LINE_1",
      "titleRow2": "HOME.PRICING.MIDDLE_CARD.TITLE_LINE_2",
      "priceRow": "HOME.PRICING.RIGHT_CARD.PRICE",
      "PeriodRow": "HOME.PRICING.MIDDLE_CARD.DURATION",
      "properties": [
        "HOME.PRICING.MIDDLE_CARD.PROPERTY_1_POSITIVE",
        "HOME.PRICING.MIDDLE_CARD.PROPERTY_2",
        "HOME.PRICING.MIDDLE_CARD.PROPERTY_3"
      ],
      "version": 0
    },
    "version": 1
  },
  "gallery": [
    {
      "id": 20,
      "alt": "-",
      "url": "https://cdn.determinacjagym.pl/gallery%2F4db7e081-9b51-471f-8f9b-89c4ba625909",
      "uuid": "4db7e081-9b51-471f-8f9b-89c4ba625909",
      "position": 21
    },
    {
      "id": 16,
      "alt": "-",
      "url": "https://cdn.determinacjagym.pl/gallery%2F83daeec6-b04b-4746-b79c-6dceeb536005",
      "uuid": "83daeec6-b04b-4746-b79c-6dceeb536005",
      "position": 20
    },
    {
      "id": 10,
      "alt": "-",
      "url": "https://cdn.determinacjagym.pl/gallery%2Ffa1f0d8d-fc38-4c35-9f9a-1029c96e547c",
      "uuid": "fa1f0d8d-fc38-4c35-9f9a-1029c96e547c",
      "position": 19
    },
    {
      "id": 14,
      "alt": "-",
      "url": "https://cdn.determinacjagym.pl/gallery%2F33a88475-cf18-4cc3-aaf5-df490cf50156",
      "uuid": "33a88475-cf18-4cc3-aaf5-df490cf50156",
      "position": 18
    },
    {
      "id": 13,
      "alt": "-",
      "url": "https://cdn.determinacjagym.pl/gallery%2F18a1082f-db06-42ff-bf4d-4f05c41d7119",
      "uuid": "18a1082f-db06-42ff-bf4d-4f05c41d7119",
      "position": 17
    },
    {
      "id": 12,
      "alt": "-",
      "url": "https://cdn.determinacjagym.pl/gallery%2F4a66c822-9771-471f-9315-893ab6348fbf",
      "uuid": "4a66c822-9771-471f-9315-893ab6348fbf",
      "position": 16
    },
    {
      "id": 11,
      "alt": "-",
      "url": "https://cdn.determinacjagym.pl/gallery%2F96e7bd0b-4b7d-4849-ba5f-1217829c680c",
      "uuid": "96e7bd0b-4b7d-4849-ba5f-1217829c680c",
      "position": 15
    },
    {
      "id": 9,
      "alt": "-",
      "url": "https://cdn.determinacjagym.pl/gallery%2F74ff8c29-d868-4135-b978-3b8308b4bd98",
      "uuid": "74ff8c29-d868-4135-b978-3b8308b4bd98",
      "position": 14
    },
    {
      "id": 8,
      "alt": "-",
      "url": "https://cdn.determinacjagym.pl/gallery%2F86776989-8d1b-401a-9d19-d9f277a04d61",
      "uuid": "86776989-8d1b-401a-9d19-d9f277a04d61",
      "position": 13
    },
    {
      "id": 7,
      "alt": "-",
      "url": "https://cdn.determinacjagym.pl/gallery%2Fdb5cddcc-37f6-4c64-a8df-1d89bd1d1665",
      "uuid": "db5cddcc-37f6-4c64-a8df-1d89bd1d1665",
      "position": 12
    },
    {
      "id": 6,
      "alt": "-",
      "url": "https://cdn.determinacjagym.pl/gallery%2F7d44bbdb-ff5d-4723-9490-c52d64e3c346",
      "uuid": "7d44bbdb-ff5d-4723-9490-c52d64e3c346",
      "position": 11
    },
    {
      "id": 5,
      "alt": "-",
      "url": "https://cdn.determinacjagym.pl/gallery%2F79b171b7-15d9-43d6-82f1-450cfa3aea68",
      "uuid": "79b171b7-15d9-43d6-82f1-450cfa3aea68",
      "position": 10
    },
    {
      "id": 22,
      "alt": "-",
      "url": "https://cdn.determinacjagym.pl/gallery%2Fcd58ea56-f728-4939-a5de-272f19b0be9b",
      "uuid": "cd58ea56-f728-4939-a5de-272f19b0be9b",
      "position": 9
    },
    {
      "id": 4,
      "alt": "-",
      "url": "https://cdn.determinacjagym.pl/gallery%2Ff83dafa8-0cd2-4d36-899f-ecf70cdfee91",
      "uuid": "f83dafa8-0cd2-4d36-899f-ecf70cdfee91",
      "position": 8
    },
    {
      "id": 17,
      "alt": "-",
      "url": "https://cdn.determinacjagym.pl/gallery%2F8335a6de-b065-499a-ac1f-02a87e849a35",
      "uuid": "8335a6de-b065-499a-ac1f-02a87e849a35",
      "position": 7
    },
    {
      "id": 3,
      "alt": "-",
      "url": "https://cdn.determinacjagym.pl/gallery%2F0d3f93f6-2267-422c-8f6f-09701e6ef6fb",
      "uuid": "0d3f93f6-2267-422c-8f6f-09701e6ef6fb",
      "position": 6
    },
    {
      "id": 18,
      "alt": "-",
      "url": "https://cdn.determinacjagym.pl/gallery%2F5e49e689-2641-44b6-9c6e-b22f0e9e8ca8",
      "uuid": "5e49e689-2641-44b6-9c6e-b22f0e9e8ca8",
      "position": 5
    },
    {
      "id": 2,
      "alt": "-",
      "url": "https://cdn.determinacjagym.pl/gallery%2Fbe2169b0-68a6-4842-ae66-8b3487598e35",
      "uuid": "be2169b0-68a6-4842-ae66-8b3487598e35",
      "position": 4
    },
    {
      "id": 19,
      "alt": "-",
      "url": "https://cdn.determinacjagym.pl/gallery%2Fa9ed867e-269b-4a5a-93e9-a6ac02900f81",
      "uuid": "a9ed867e-269b-4a5a-93e9-a6ac02900f81",
      "position": 3
    },
    {
      "id": 1,
      "alt": "-",
      "url": "https://cdn.determinacjagym.pl/gallery%2F89743731-8f80-48d3-938c-71d4e35c2c1c",
      "uuid": "89743731-8f80-48d3-938c-71d4e35c2c1c",
      "position": 2
    },
    {
      "id": 21,
      "alt": "-",
      "url": "https://cdn.determinacjagym.pl/gallery%2F0c1858e8-81dd-4c1f-be45-e15eed8993a6",
      "uuid": "0c1858e8-81dd-4c1f-be45-e15eed8993a6",
      "position": 1
    },
    {
      "id": 15,
      "alt": "-",
      "url": "https://cdn.determinacjagym.pl/gallery%2F92ef9950-c983-4606-8fdf-f4f5b8541161",
      "uuid": "92ef9950-c983-4606-8fdf-f4f5b8541161",
      "position": 0
    }
  ]
};

export const globalAppProfiles: components['schemas']['AppUserProfilePublic'][] = [
  {
    "userId": 6,
    "email": "",
    "name": "Maksymilian Mucha",
    "youtubeUrl": "",
    "instagramUrl": "",
    "websiteUrl": "",
    "avatarUrl": "https://cdn.determinacjagym.pl/avatars%2F6?1731707292",
    "about": {
      "about": {
        "en": {
          "lang": "en",
          "shortDescription": "",
          "htmlPage": "",
          "title": "Trener Personalny"
        },
        "pl": {
          "lang": "pl",
          "shortDescription": "",
          "htmlPage": "",
          "title": "Trener Personalny"
        }
      },
      "languages": [
        "pl"
      ]
    }
  },
  {
    "userId": 7,
    "email": "",
    "name": "Małgorzata Mucha",
    "youtubeUrl": "",
    "instagramUrl": "",
    "websiteUrl": "",
    "avatarUrl": "https://cdn.determinacjagym.pl/avatars%2F7?1731707292",
    "about": {
      "about": {
        "en": {
          "lang": "en",
          "shortDescription": "",
          "htmlPage": "",
          "title": "Trener Personalny"
        },
        "pl": {
          "lang": "pl",
          "shortDescription": "",
          "htmlPage": "",
          "title": "Trener Personalny"
        }
      },
      "languages": [
        "pl",
        "en"
      ]
    }
  }
];

export const globalAvailableLanguages = ['en', 'pl'] as const;

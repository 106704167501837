import { useEffect, useState } from "react";
import { globalAppTranslations } from "./cms-data.generated";

let currentLang = "pl";
let initialized = false;

// runtime only
// namespace -> translations (lang won't change during runtime)
const dynamicTranslations: Record<string, Record<string, any>> = {};

// namespace -> bool
const fetchingMap: Record<string, boolean> = {};

// cache works during ssr as well
// lang -> namespace -> key -> value
const translationsKeysCache: Record<string, Record<string, Record<string, string>>> = {};

export type TranslationsOptions = { ns?: string };

export const setCurrentLang = (lang: string) => {
  currentLang = lang;
};

export const getCurrentLang = () => {
  log(`returning getCurrentLang: ${currentLang}`);
  return currentLang;
};

export function exists(key: string, options?: TranslationsOptions): boolean {
  const res = t(key, options);
  return res !== key;
}

function log(...content: any) {
  // console.log(...content);
}

function onMissingTranslation(key: string, ns: string) {
  if (ns === "services") return;
  console.log(`#MISSING_TRANSLATION key: ${key} namepsace: ${ns} lang: ${getCurrentLang()}`);
}

export const t = (key: string, options?: TranslationsOptions): string => {
  if (!key) {
    console.warn("Translation key is empty!");
    return key;
  }

  let requestedNamespace = "common";
  let rootTranslations = globalAppTranslations[currentLang as "pl" | "en"];
  if (options?.ns) {
    requestedNamespace = options?.ns;
    rootTranslations = dynamicTranslations[options.ns];
    if (!rootTranslations) {
      fetchNamespaceTranslations(currentLang, options.ns);
      return key;
    }
  }

  const cachedKey = getFromCache(key, requestedNamespace);
  if (cachedKey) {
    log(`returning cached value ${cachedKey} for key ${key} and ns ${requestedNamespace}`);
    return cachedKey;
  }

  if (!rootTranslations) {
    console.warn(`Translations for lang ${currentLang} not found`);
    return key;
  }

  let evaluatedPart = rootTranslations;
  for (const translationPart of key.split(".")) {
    evaluatedPart = evaluatedPart[translationPart];
    if (!evaluatedPart) {
      onMissingTranslation(key, requestedNamespace);
      return key;
    }
  }

  if (typeof evaluatedPart !== "string") {
    onMissingTranslation(key, requestedNamespace);
    return key;
  }

  if (!evaluatedPart) throw new Error("Translation not found [1]");

  const langCache = translationsKeysCache[currentLang];
  if (!langCache) translationsKeysCache[currentLang] = {};

  const langNsCache = translationsKeysCache[currentLang][requestedNamespace];
  if (!langNsCache) {
    translationsKeysCache[currentLang][requestedNamespace] = {};
  }

  translationsKeysCache[currentLang][requestedNamespace][key] = evaluatedPart;
  log(`added key ${key} with value ${evaluatedPart} to cache with ns ${requestedNamespace}`);

  return evaluatedPart;
};

export function getLangFromPath(path: string): string {
  if (path.startsWith("/en")) {
    return "en";
  }

  return "pl";
}

export function getLangFromUri(uri: string): string {
  return getLangFromPath(new URL(uri).pathname);
}

export function withCurrentLang(path: string): string {
  if (!path.startsWith("/")) {
    path = `/${path}`;
  }

  if (window.location.pathname.startsWith("/en")) {
    return `/en${path}`;
  }

  return path;
}

function getFromCache(key: string, namespace: string) {
  const langCache = translationsKeysCache[currentLang];
  if (!langCache) {
    return undefined;
  }

  const cacheForNs = langCache?.[namespace];
  if (!cacheForNs) {
    return undefined;
  }

  return cacheForNs?.[key];
}

async function fetchNamespaceTranslations(lang: string, ns: string) {
  if (fetchingMap[ns]) return;
  fetchingMap[ns] = true;

  const apiClient = (await import("./api/client")).apiClient;

  const res = await apiClient.GET("/settings/dictionaries/{key}/{lang}", {
    params: {
      path: {
        key: ns,
        lang,
      },
    },
  });

  if (res.error) {
    console.error("Error fetching translations", res.error);
    return;
  }

  dynamicTranslations[ns] = res.data as any;

  translationEventTarget.dispatchEvent(new CustomEvent("translationChange"));

  delete fetchingMap[ns];
}

const translationEventTarget = new EventTarget();

export function useTranslationsRerender() {
  const [_, setUpdate] = useState(0);

  useEffect(() => {
    const sub = () => {
      setUpdate((prev) => prev + 1);
    };

    translationEventTarget.addEventListener("translationChange", sub);

    return () => {
      translationEventTarget.removeEventListener("translationChange", sub);
    };
  });
}

// required to use with namespaces that are fetched dynamically
export function useTranslations(key: string, opts?: TranslationsOptions) {
  const [_, setUpdate] = useState(0);

  useEffect(() => {
    const sub = () => {
      setUpdate((prev) => prev + 1);
    };

    translationEventTarget.addEventListener("translationChange", sub);

    return () => {
      translationEventTarget.removeEventListener("translationChange", sub);
    };
  });

  return t(key, opts);
}
